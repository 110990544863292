.app.spectrum-1 .spectrum-color,
.app.spectrum-1 a {
  color: #FF3365;
}

.app.spectrum-2 .spectrum-color,
.app.spectrum-2 a {
  color: #E72CF7;
}

.app.spectrum-3 .spectrum-color,
.app.spectrum-3 a {
  color: #9100FF;
}

.app.spectrum-4 .spectrum-color,
.app.spectrum-4 a {
  color: #105BFF;
}

.app.spectrum-5 .spectrum-color,
.app.spectrum-5 a {
  color: #0094F7;
}

.app.spectrum-6 .spectrum-color,
.app.spectrum-6 a {
  color: #00CCCC;
}

.app.spectrum-7 .spectrum-color,
.app.spectrum-7 a {
  color: #11E27A;
}

.app.spectrum-1 .spectrum-background,
.app.spectrum-1 ::selection {
  background-color: #FF3365;
}

.app.spectrum-2 .spectrum-background,
.app.spectrum-2 ::selection {
  background-color: #E72CF7;
}

.app.spectrum-3 .spectrum-background,
.app.spectrum-3 ::selection {
  background-color: #9100FF;
}

.app.spectrum-4 .spectrum-background,
.app.spectrum-4 ::selection {
  background-color: #105BFF;
}

.app.spectrum-5 .spectrum-background,
.app.spectrum-5 ::selection {
  background-color: #0094F7;
}

.app.spectrum-6 .spectrum-background,
.app.spectrum-6 ::selection {
  background-color: #00CCCC;
}

.app.spectrum-7 .spectrum-background,
.app.spectrum-7 ::selection {
  background-color: #11E27A;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #101010;
  font-family: "Inter", -apple-system, sans-serif;
  color: #ffffff;
}

h1, h2, h3, h4, h5, p, ul {
  padding: 0;
  margin: 0;
}

h2 {
  margin: 20px 0;
  font-size: 32px;
  letter-spacing: -0.5px;
  font-weight: 700;
}

h3 {
  margin-bottom: 18px;
  font-size: 20px;
}

p {
  width: 100%;
  margin: 0 0 20px 0;
  font-size: 18px;
  line-height: 24px;
  color: #dadada;
}

figcaption {
  margin: 0;
  font-size: 14px;
  color: #808080;
}

a {
  border-bottom: 1px solid;
  border-color: transparent;
  text-decoration: none;
  transition: border-color 0.167s ease-out;
}
a:hover {
  border-color: inherit;
}

ul {
  margin: 12px 0;
  list-style-type: none;
}

hr {
  display: inline-block;
  width: 100%;
  height: 2px;
  border: none;
  border-radius: 10px;
  background-color: #202020;
}
hr.short {
  width: 42px;
  margin-bottom: 20px;
}

::selection {
  color: #ffffff;
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in.is-visible {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.grid {
  display: grid;
  grid-template-columns: auto 768px auto;
  grid-template-rows: auto auto auto;
  grid-template-areas: ". header ." ". main ." ". footer .";
  grid-gap: 0;
}
@media (max-width: 768px) {
  .grid {
    grid-template-columns: auto;
    grid-template-areas: "header" "main" "footer";
  }
}

header {
  grid-area: header;
}

main {
  grid-area: main;
}

footer {
  grid-area: footer;
}

header {
  margin: 120px 0;
  user-select: none;
  white-space: nowrap;
}

.logo {
  display: flex;
  justify-content: flex-start;
}

.logo-lines {
  width: 14px;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1000px) {
  .logo-lines {
    width: 12vw;
  }
}
@media (max-width: 768px) {
  .logo-lines {
    width: 20vw;
  }
}

.logo-lines-shape-wrapper {
  width: fit-content;
}

.logo-line-shape {
  height: 2px;
  margin: 2px 0;
  border-radius: 10px;
}

.logo-line-shape#line-1 {
  width: 10004.5px;
  background-color: #FF3365;
}

.logo-line-shape#line-2 {
  width: 10002px;
  background-color: #E72CF7;
}

.logo-line-shape#line-3 {
  width: 10000.5px;
  background-color: #9100FF;
}

.logo-line-shape#line-4 {
  width: 10000px;
  background-color: #105BFF;
}

.logo-line-shape#line-5 {
  width: 10000.5px;
  background-color: #0094F7;
}

.logo-line-shape#line-6 {
  width: 10002px;
  background-color: #00CCCC;
}

.logo-line-shape#line-7 {
  width: 10004.5px;
  background-color: #11E27A;
}

img.logo-text {
  display: block;
  width: auto;
  height: 30px;
  position: relative;
  top: -1px;
  left: 5px;
}

main {
  padding: 0 20px;
}

section {
  width: 100%;
  padding-bottom: 12px;
}

.projects figure {
  width: fit-content;
  margin: 0 auto 20px auto;
}
.projects figure img {
  display: block;
  max-width: 100%;
  width: 100%;
  user-select: none;
}
.projects figure figcaption {
  margin-top: 10px;
  user-select: none;
  cursor: default;
}
.projects figure.inflation-station-branding img {
  max-width: 400px;
}
.projects figure.lumics-branding img {
  max-width: 500px;
}
.projects figure.lumics-sidebar img {
  max-width: 280px;
}

img.headshot {
  display: block;
  float: right;
  width: 150px;
  margin: 20px 10px 20px 40px;
  border-radius: 50%;
}
@media (max-width: 390px) {
  img.headshot {
    float: none;
    margin: 0 auto 20px auto;
  }
}

section.skills li {
  display: inline-block;
  padding: 6px 12px;
  margin: 1px;
  background-color: #303030;
  text-shadow: 0 0.5px 3px rgba(0, 0, 0, 0.33);
  cursor: default;
  transition: background-color 0.167s ease-out;
}

section.skills li.skill-html:hover {
  background-color: #E44D27;
}
section.skills li.skill-css:hover {
  background-color: #215EAA;
}
section.skills li.skill-sass:hover {
  background-color: #BF4080;
}
section.skills li.skill-javascript:hover {
  background-color: #D4B732;
}
section.skills li.skill-ruby:hover {
  background-color: #930E04;
}
section.skills li.skill-react:hover {
  background-color: #2DA4C4;
}
section.skills li.skill-aurelia:hover {
  background-color: #653D9A;
}
section.skills li.skill-node:hover {
  background-color: #3D863C;
}
section.skills li.skill-rubyonrails:hover {
  background-color: #D30002;
}
section.skills li.skill-postgresql:hover {
  background-color: #336791;
}
section.skills li.skill-mongodb:hover {
  background-color: #023430;
}
section.skills li.skill-git:hover {
  background-color: #F54D27;
}
section.skills li.skill-liquid:hover {
  background-color: #0C83CD;
}
section.skills li.skill-shopify:hover {
  background-color: #95BF47;
}
section.skills li.skill-statamic:hover {
  background-color: #FE259E;
}
section.skills li.skill-wordpress:hover {
  background-color: #3957E9;
}
section.skills li.skill-drupal:hover {
  background-color: #0D78BE;
}
section.skills li.skill-uiuxdesign:hover {
  background-color: #005CFF;
}
section.skills li.skill-sketch:hover {
  background-color: #FFBB01;
}
section.skills li.skill-figma:hover {
  background-color: #A259FF;
}
section.skills li.skill-photoshop:hover {
  background-color: #39B0FD;
}
section.skills li.skill-illustrator:hover {
  background-color: #FF9601;
}

footer {
  padding: 20px;
}

footer span {
  color: #808080;
}
